
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































































































































































































































































































.submit {
  @include loading($c-orange);
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex !important; /* stylelint-disable-line declaration-no-important */
}

::v-deep .fatline {
  color: $c-main-primary;
}

::v-deep .fatline::after {
  background-color: $c-main-primary;
}

.phone {
  display: flex;
  width: 100%;
  color: $c-gray;
  gap: 15px;

  ::v-deep .input {
    width: 100%;
  }
}

.phone__flag {
  flex-shrink: 0;
  width: 2rem;
  margin-right: 0.5rem;
}

.country-codes {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid $c-gray-lighter;

  ::v-deep .placeholder {
    justify-content: flex-end;
  }

  ::v-deep .options {
    width: 9rem;
  }
}

.resend-sms {
  cursor: pointer;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.nav__message {
  min-width: 30rem;
  margin-right: 2rem;

  @include mq(l) {
    flex-basis: 60%;
  }
}
