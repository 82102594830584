
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































































.form-validation {
  display: flex;
}
.submit-number {
  margin-left: 25%;
  height: 100%;
  width: 75%;
}
.sms-resend {
  line-height: 1.5em;
  margin-top: 50px;
  cursor: pointer;
}
.validation-label {
  margin-bottom: 5px;
}
@media (max-width: 990px) {
  .submit-btn-parent {
    text-align: center;
  }
  .submit-number {
    margin-left: 0;
    width: auto;
  }
}
